'use strict';

import axios from 'axios';
import utils from '@/features/utils.js';

let config = {
  baseURL: utils.get_api_base_url(),
  withCredentials: true
};
const AXIOS = axios.create( config );

export default AXIOS;