const VueNotificationOptions = Object.freeze( {
  'message': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  'type': 'info',
  'showIcon': true,
  'dismiss': {
    'manually': true,
    'automatically': true
  },
  'duration': 5000,
  'showDurationProgress': true,
  'appearance': 'dark'
} );

export default VueNotificationOptions;

// Documentation
// {
//   "message": string,
//   "type": string ("info"|"warning"|"alert"|"success"),
//   "showIcon": boolean,
//   "dismiss": {
//     "manually": boolean,
//     "automatically": boolean
//   },
//   "duration": number,
//   "showDurationProgress": boolean,
//   "appearance": string ("light"|"dark"|"glass")
// }