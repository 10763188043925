const SORT = Object.freeze( {
  REGISTER_DATE_ASC: 'Register date ascending',
  REGISTER_DATE_DESC: 'Register date descending (Default)',
  LAST_ACTIVITY_ASC: 'Last activity ascending',
  LAST_ACTIVITY_DESC: 'Last activity descending',
  RANKING_ASC: 'Ranking ascending',
  RANKING_DESC: 'Ranking descending',
  TITLE_ASC: 'Title A-Z',
  TITLE_DESC: 'Title Z-A',
  DESCRIPTION_ASC: 'Description A-Z',
  DESCRIPTION_DESC: 'Description Z-A'
} );

export default SORT;