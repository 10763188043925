const FILTER = Object.freeze( {
  ALL: 'All (Default)',
  WITH_PILOT: 'With a pilot',
  WITHOUT_PILOT: 'Without a pilot',
  IDENTIFIED: 'Identified Singular',
  PLANNED: 'Planned Singular',
  COMPLETED: 'Completed',
  WITH_SECURING_DATE: 'With a securing date',
  WITHOUT_SECURING_DATE: 'Without a securing date',
  EFFICIENT: 'Efficient'
} );

export default FILTER;