import { defineStore } from 'pinia';
import Cookies from 'js-cookie';
import moment from 'moment';
import SORT from '@/constants/sort.constants';
import FILTER from '@/constants/filter.constants';

const cookiesConfig = {
  expires: ( process.env.VUE_APP_SESSION_EXPIRES_IN / 1440 ),
  secure: true,
  samesite: 'lax'
};

const cookies = Cookies.withAttributes( cookiesConfig );

const cookiesStorage = {
  getItem: ( key ) => cookies.get( key ),
  setItem: ( key, value ) => cookies.set( key, value ),
  removeItem: ( key ) => cookies.remove( key )
};

export const useStore = defineStore( 'store', {
  state: () => ( {
    localStorage: {
      i18nLocale: process.env.VUE_APP_I18N_LOCALE || process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'fr',
      lastRPVisitedURL: '',
      expanders: {
        description: true,
        causes: false,
        securingActions: false,
        actions: false,
        documents: false,
        notes: false,
        capitalisingActions: false
      },
      display: {
        dashboard: {
          row_per_page: 3
        },
        board: {
          start_date: moment().startOf( 'day' ).toISOString(),
          n_days: 5,
          selectedRP: '',
          archiveView: false
        }
      },
      causes: {
        sort: SORT.REGISTER_DATE_DESC,
        filter: FILTER.ALL,
        ishikawaView: false
      },
      securingActions: {
        sort: SORT.REGISTER_DATE_DESC,
        filter: FILTER.ALL
      },
      actions: {
        sort: SORT.REGISTER_DATE_DESC,
        filter: FILTER.ALL
      },
      capitalisingActions: {
        sort: SORT.REGISTER_DATE_DESC,
        filter: FILTER.ALL
      },
      notes: {
        sort: SORT.REGISTER_DATE_DESC,
        filter: FILTER.ALL
      }
    },
    auth: {
      connect: false,
      token: ''
    },
    auth_flashmeeting: {
      url: '',
      automatic: false
    },
    auth_user: null
  } ),
  getters: {
    isAuthenticated: ( state ) => {
      return state.auth.connect;
    },
    getConnectedUser: ( state ) => {
      return state.auth_user;
    },
    getLocale: ( state ) => {
      return state.localStorage.i18nLocale;
    },
    getToken: ( state ) => {
      return state.auth.token;
    },
    getAuthFlashmeeting: ( state ) => {
      return state.auth_flashmeeting;
    },
    getLastRPVisitedURL: ( state ) => {
      return state.localStorage.lastRPVisitedURL;
    },
    getExpanders: ( state ) => {
      return state.localStorage.expanders;
    },
    getDisplay: ( state ) => {
      return state.localStorage.display;
    },
    getCausesOptions: ( state ) => {
      return state.localStorage.causes;
    },
    getSecuringActionsOptions: ( state ) => {
      return state.localStorage.securingActions;
    },
    getActionsOptions: ( state ) => {
      return state.localStorage.actions;
    },
    getCapitalisingActionsOptions: ( state ) => {
      return state.localStorage.capitalisingActions;
    },
    getNotesOptions: ( state ) => {
      return state.localStorage.notes;
    }
  },
  actions: {
    setLocale( { locale } ) {
      moment.locale( locale );
      this.localStorage.i18nLocale = locale ?? process.env.VUE_APP_I18N_FALLBACK_LOCALE;
    },
    updateToken( { token } ) {
      this.auth.token = token != null ? token : '';
    },
    setAuthFlashmeeting( { url, automatic } ) {
      this.auth_flashmeeting.url = url != null ? url : '';
      this.auth_flashmeeting.automatic = automatic != null ? Boolean( automatic ) : false;
    },
    setConnected( { connect, connect_user, token } ) {
      this.auth_user = connect_user != null ? connect_user : null;
      this.auth.connect = connect != null ? Boolean( connect ) : false;
      this.auth.token = token != null ? token : '';
    },
    setLastRPVisitedURL( newRPVisitedUrl ) {
      this.localStorage.lastRPVisitedURL = newRPVisitedUrl;
    },
    setExpanders( newExpanderState ) {
      this.localStorage.expanders = Object.assign( this.localStorage.expanders, newExpanderState );
    },
    setDisplayDashboard( newOptions ) {
      this.localStorage.display.dashboard = Object.assign( this.localStorage.display.dashboard, newOptions );
    },
    setDisplayBoard( newOptions ) {
      this.localStorage.display.board = Object.assign( this.localStorage.display.board, newOptions );
    },
    setSecuringActions( newSecuringActionsOption ) {
      this.localStorage.securingActions = Object.assign( this.localStorage.securingActions, newSecuringActionsOption );
    },
    setCauses( newCausesOption ) {
      this.localStorage.causes = Object.assign( this.localStorage.causes, newCausesOption );
    },
    setActions( newActionsOption ) {
      this.localStorage.actions = Object.assign( this.localStorage.actions, newActionsOption );
    },
    setCapitalisingActions( newCapitalisingActionsOption ) {
      this.localStorage.capitalisingActions = Object.assign( this.localStorage.capitalisingActions, newCapitalisingActionsOption );
    }
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'resolution_local_storage',
        storage: localStorage,
        paths: [ 'localStorage' ]
      },
      {
        key: 'resolution_cookie',
        storage: cookiesStorage,
        paths: [ 'auth', 'auth_flashmeeting' ]
      }
    ],
  },
} );